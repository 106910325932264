import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Typography from "/src/components/ui/base/typography"
import { graphql, Link } from "gatsby"
import Header from "../../components/ui/base/layout/header"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./index.module.scss"
import Section from "../../components/ui/base/Section"
import classnames from "classnames"
import Breadcrumbs from "../../components/ui/base/Breadcrumbs"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useTranslation } from "react-i18next"
import { BASE_URL, PHOTOGRAPHERS_URL } from "../../util/constants"

const PhotographersPage = ({ data }) => {

  const { i18n: l  } = useTranslation();
  const headerPicture = data?.allFile.edges[0].node.childImageSharp.gatsbyImageData;
  const photographersLink = `${BASE_URL[l.language]}/${PHOTOGRAPHERS_URL[l.language]}/`
  return (
    <>
      <GatsbySeo
        htmlAttributes={{ lang : l.language }}
        title={"Tutti i Fotografi WeShoot"}
        language={l.language}
        description={"Passione, allegria, professionalitá. Queste sono alcune delle peculiaritá dei fotografi certificati WeShoot. Parti in sicurezza con i nostri coach. "}
        canonical={photographersLink}
      />
      <Header
        img={headerPicture}
        size={"compact"}
        theme="dark"
      >
        <Container fluid="xl">
          <Row className="align-items-center">
            <Col lg={12}>
              <div className="intro_title text-center">
                <Typography className="animated fadeInDown" variant="heading1">
                Scopri i tuoi coach
                </Typography>
                <Breadcrumbs elements={[
                  {
                    name: "WeShoot",
                    path: "/",
                  },
                  {
                    name: "Fotografi",
                    path: "/fotografi/",
                  }
                ]} />
              </div>
            </Col>
          </Row>
        </Container>
      </Header>
      <Section
        className={classnames([styles.photographers])}
        variant={"grey"}
        title={"Parti in viaggio con noi"}
        subtitle={""}>
          {data?.allStrapiAllUsers.edges?.map(p => {
            return (
              <div  className={classnames([styles.photographer])}>
                {p.node.profilePicture?.localFile &&
                <GatsbyImage className={classnames([styles.profilePicture])} alt={`Fotografo ${p.node.username} ${p.node.firstName}`} image={p.node.profilePicture.localFile.childImageSharp.gatsbyImageData}/>
                }
                <div  className={classnames([styles.details])}>
                    <Typography variant="heading5"> <Link to={"/fotografi/"+ p.node.username }>{p.node.firstName} {p.node.lastName}</Link></Typography>
                  {p.node.location && <div><i className="icon-home"/> {p.node.location}</div>}

                  {p.node.instagram && <Link className={classnames([styles.instagram])} to={`https://www.instagram.com/${p.node.instagram}`}>  <i className="icon-instagram"/></Link>}

                </div>
              </div>
            )
          })
          }
      </Section>




    </>
  )
}

export default PhotographersPage

export const query = graphql`
  query PhotographersQuery {
  
  allFile(filter: {name: {eq: "mission"}}) {
            edges {
              node {
                ...ImageFragment
              }
            }
        }
    allStrapiReview(sort: {fields: published_at, order: DESC}) {
    edges {
      node {
        title
        tour
        user {
          firstName
          profilePicture {
            localFile {
              absolutePath
            }
          }
        }
        rating
        description
        created_at
      }
    }
  }
  
 allStrapiAllUsers(filter: { level: {eq: "coach"}}) {
    edges {
      node {
        bio
        firstName
        instagram
        lastName
        username
        profilePicture {
          localFile {
              ...ImageFragment
          }
        }
      }
    }
  }
}

`


